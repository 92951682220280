export function compareObjectsByKey(obj1, obj2, keysToCompare) {
    const notEqualKeys = [];
    let isModified = false;
    for (const key of keysToCompare) {
        if (obj1?.[key] !== obj2?.[key]) {
            notEqualKeys.push(key);
            isModified = true;
        }
    }
    return { isModified, notEqualKeys };
}
